import { createWebHistory, createRouter } from "vue-router";
import { isUserLoggedIn } from '@/helpers/utils'
import env from '../env.js'
import routes from './routes'

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes,
  mode: 'history',
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0, left: 0 }
    }
  },
});

// Before each route evaluates...
router.beforeEach(async(to, from, next) => {
  await new Promise((resolve, reject) => {
    const isLoggedIn = isUserLoggedIn();
    if(to.meta.authRequired) {
      if (!isLoggedIn) return next({path: '/auth', query:{ token: to.query.token, intended_url: to.path }});
    }
    if(to.meta.guestRequired) {
      if (isLoggedIn) return next({path: '/' });
    }
    resolve()
  });
  return next();
});

router.beforeResolve(async (to, from, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of to.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(to, from, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              // Complete the redirect.
              next(...args)
              reject(new Error('Redirected'))
            } else {
              resolve()
            }
          })
        } else {
          // Otherwise, continue resolving the route.
          resolve()
        }
      })
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return
  }
  document.title = to.meta.title + ' | ' +env.appName;
  // If we reach this point, continue resolving the route.
  return next()
});

export default router
