import axios from "@/plugins/axios.js"

export const actions = {
  fetchActivities({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("/activities")
        .then((response) => {
          if(response.data.success){
              commit('SET_ACTIVITIES', response.data.data)
            }
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  markAsRead({ commit }, actId) {
    return new Promise((resolve, reject) => {
      axios.get(`/activities/${actId}/markasread`)
        .then((response) => {
          if(response.data.success){
            commit('MARK_AS_READ', actId)
          }
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}

export const getters = {
  // getItem: state => (productId) => state.products.find((product) => product.id == productId),
}

export const state = {
  list: [],
}

export const mutations = {
  SET_ACTIVITIES(state, activities) {
    state.list = activities
  },
  ADD_ACTIVITY(state, activity) {
    state.list.unshift(activity)
  },
  DELETE_ACTIVITY(state, actId) {
    const ItemIndex = state.list.findIndex((item) => item.id == actId)
    state.list.splice(ItemIndex, 1)
  },
  MARK_AS_READ(state, actId){
  	state.list.map((item)=>{
  		if(item.id == actId){
  			return item.is_read = 1
  		}
  	})
  }
}

export default {
  isRegistered: true,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

