import countries from "./db/countries"
import env from '../env.js'
import { getUserData } from '@/helpers/utils'

export default {
    isLoading: false,
    activeUser: getUserData() || null,
    config:{
        ...env,
    },
    catalog:{

    },
    country: {},
    countries: countries,
    invoices:[]
}
