
export default {
    windowBreakPoint: state => {

        // This should be same as tailwind. So, it stays in sync with tailwind utility classes
        if (state.windowWidth >= 1200) return "xl"
        else if (state.windowWidth >= 992) return "lg"
        else if (state.windowWidth >= 768) return "md"
        else if (state.windowWidth >= 576) return "sm"
        else return "xs"
    },
    getCountry:(state) => {
        if(!state.country.code) return null
        return state.countries.find((con) => con.code == state.country.code);
    },
}
